<template>
  <div>
    <div class="top" style="margin: 20px 0">
      <el-button type="primary" @click="addDialog()" style="margin-right: 15px"
        >新增</el-button
      >
    </div>
    <el-dialog :title="title" :visible.sync="dialogTableVisible">
      <el-form
        :model="dataList"
        ref="dataList"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="员工名称">
          <el-input v-model="dataList.userName"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input
            v-model="dataList.loginUser"
            type="number"
            @input="dataList.loginUser = dataList.loginUser.slice(0, 11)"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-radio-group v-model="dataList.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">冻结</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUpdate">确 定</el-button>
      </span>
    </el-dialog>
    <el-table :data="tableData" style="width: 100%" :stripe="true">
      <el-table-column prop="userName" label="姓名"> </el-table-column>
      <el-table-column prop="loginUser" label="账号"> </el-table-column>
      <el-table-column prop="createTime" label="注册时间"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span style="color: #31a84f" v-if="scope.row.status == 1">启用</span>
          <span style="color: #979898" v-if="scope.row.status == 0"
            >已冻结</span
          >
        </template>
      </el-table-column>
      <el-table-column label="设置">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="updateDialog(scope.row)"
            style="margin-right: 10px"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="success"
            @click="statusUpdate(scope.row)"
            v-if="scope.row.status == 0"
            style="margin-left: 0px"
            >启用</el-button
          >

          <!-- <el-popconfirm
            v-if="scope.row.status == 1"
            title="您确定要将这个用户冻结吗？"
            @onConfirm="statusUpdate(scope.row)"
          >
            <el-button size="small" type="danger" slot="reference"
              >冻结</el-button
            >
          </el-popconfirm> -->
          <el-button
            v-if="scope.row.status == 1"
            size="small"
            type="danger"
            @click="statusUpdate(scope.row)"
            >冻结</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="queryList.pageIdx"
      :limit.sync="queryList.pageSize"
      @pagination="pagination"
    />
  </div>
</template>

<script>
function jialing(num) {
  if (num >= 10) {
    return num;
  } else {
    return "0" + num;
  }
}
var day1 = new Date();
day1.setTime(day1.getTime());
var s1 =
  day1.getFullYear() +
  "-" +
  jialing(day1.getMonth() + 1) +
  "-" +
  jialing(day1.getDate());
var day3 = new Date();
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
var s3 =
  day3.getFullYear() +
  "-" +
  jialing(day3.getMonth() + 1) +
  "-" +
  jialing(day3.getDate());
import {
  select_all,
  add_or_update,
  find_by_id,
  update_status,
} from "../../api/AccountManagement";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      time: [s1, s3],
      queryList: {
        pageIdx: 1,
        pageSize: 10,
        token: sessionStorage.getItem("token"),
      },
      options: {},
      tableData: [],
      total: 0,
      title: "新增员工",
      dialogTableVisible: false,
      dataList: {
        loginUser: "",
        status: 1,
        token: sessionStorage.getItem("token"),
        userName: "",
      },
      dataListCopy: {},
    };
  },
  mounted() {
    this.queryList.startTime = s1;
    this.queryList.endTime = s3;
    this.loanList();
    this.dataListCopy = JSON.stringify(this.dataList);
  },
  methods: {
    addDialog() {
      this.dataList = JSON.parse(this.dataListCopy);
      this.dialogTableVisible = true;
      this.title = "新增员工";
    },
    updateDialog(data) {
      this.dialogTableVisible = true;
      this.title = "编辑员工";
      find_by_id({ id: data.id }).then((res) => {
        this.dataList = res.data;
        this.dataList.loginPwd = "";
      });
    },
    loanList() {
      select_all(this.queryList).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    timeUpdate(val) {
      if (val != null) {
        this.queryList.startTime = val[0];
        this.queryList.endTime = val[1];
      } else {
        this.queryList.startTime = "";
        this.queryList.endTime = "";
      }
      this.loanList();
    },
    statusUpdate(data) {
      var status = data.status == 1 ? 0 : 1;
      var statusTitle = data.status == 1 ? "冻结" : "启用";
      if (!status) {
        this.$confirm("是否确认" + statusTitle + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            update_status({
              id: data.id,
              status: status,
            }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: statusTitle + "成功",
                  type: "success",
                });
                this.loanList();
              }
            });
          })
          .catch(() => {});
      } else {
        update_status({
          id: data.id,
          status: status,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: statusTitle + "成功",
              type: "success",
            });
            this.loanList();
          }
        });
      }
    },
    handleSelectionChange() {},
    look() {},
    addUpdate() {
      this.dataList.token = sessionStorage.getItem("token");
      var title = this.title == "新增数据" ? "添加" : "修改";
      add_or_update(this.dataList).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: `${title}成功`,
            type: "success",
          });
          this.loanList();
          this.dialogTableVisible = false;
        }
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        console.log(params);
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.loanList();
    },
  },
};
</script>

<style scoped>
::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 250px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}

::v-deep thead th {
  color: #000;
  background: rgb(250, 250, 250);
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-pagination {
  text-align: center;
}

::v-deepinput[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>